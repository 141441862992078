import { z } from "zod";

export enum TipType {
  user = "user",
  account = "account",
}

export enum TipName {
  onboardingTeamSize = "onboarding_team_size",
  onboardingInvite = "onboarding_invite",
  onboardingChosenTemplate = "onboarding_chosen_template",
  canvasWalkthrough = "canvas_walkthrough",
  walkthroughExploreOrgChart = "walkthrough_explore_org_chart",
  walkthroughInviteMembers = "walkthrough_invite_members",
  walkthroughAddTemplate = "walkthrough_add_template",
  walkthroughAddElement = "walkthrough_add_element",
  walkthroughAddTask = "walkthrough_add_task",
  walkthroughCreateFrame = "walkthrough_create_frame",
  walkthroughDropComment = "walkthrough_drop_comment",
  walkthroughUploadImage = "walkthrough_upload_image",
  walkthroughPresentationMode = "walkthrough_presentation_mode",
  walkthroughExportCanvas = "walkthrough_export_canvas",
  downgradeNotice = "downgrade_notice",
  subscriptionCanceledNotice = "subscription_canceled_notice",
  inviteNotification = "invite_notification",
  restrictedUsersNotice = "restricted_users_notice",
  hiddenCursors = "hidden_cursors",
}

export const tipSchema = z.object({
  id: z.number(),
  name: z.nativeEnum(TipName),
  type: z.nativeEnum(TipType),
});

export type Tip = z.infer<typeof tipSchema>;
