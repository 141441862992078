import { MindmapOrgChartNodeElement } from "shared/datamodel/schemas/mindmap-org-chart";
import { atom } from "jotai";

export enum SidepanelType {
  none,
  framesAndComments,
  integrations,
  mondayOrgChartNodeInfo,
  orgChartNodeInfo,
}

export const sidePanelType = atom<SidepanelType>(SidepanelType.none);
export const mondayOrgChartNodeDataForSidePanel = atom<MindmapOrgChartNodeElement | null>(null);
export const orgChartNodeDataForSidePanel = atom<null | { rootid: string, selectedNode: string; editable: boolean }>(null);