// Necessary if using App Router to ensure this file runs on the client
"use client";

import { datadogRum } from "@datadog/browser-rum";

const datadogInit = () => {
  if (typeof window !== "undefined") {
    if (!(process.env.NEXT_ENV === "production" || process.env.NEXT_ENV === "staging")) {
      return;
    }

    datadogRum.init({
      applicationId: `${process.env.NEXT_DATADOG_RUM_APPLICATION_ID}`,
      clientToken: `${process.env.NEXT_DATADOG_RUM_CLIENT_TOKEN}`,
      site: "datadoghq.eu",
      service: "frontend",
      env: `${process.env.NEXT_ENV}`,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: `${process.env.NEXT_VERSION}`,
      sampleRate: 100,
      replaySampleRate: 0,
      trackInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
      // Specify URLs to propagate trace headers for connection between RUM and backend trace
      /*
      allowedTracingUrls: [
        { match: "https://example.com/api/", propagatorTypes: ["tracecontext"] },
      ],
      */
    });
  }
};

datadogInit();

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
