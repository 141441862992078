import { dropAllDatabases } from "@workcanvas/reflect/client";
import { clearAllTips, markTipUnseen } from "frontend/api";

declare global {
  interface Window {
    TestUtils: typeof utils;
  }
}

const utils = {
  deleteReflectData: dropAllDatabases,
  clearSeenTips: clearAllTips,
  markTipUnseen: markTipUnseen,
};

const testAccounts = [
  5, 14288737, 16526228, 15458881, 16446498, 18094136, 18094148, 18094156, 18094163, 18097744, 18132019,
].map((x) => x.toString()); // monday, testybesty

export function addTestUtils(accountId: string) {
  if (testAccounts.includes(accountId)) {
    console.log("Added test utils to window object");
    window.TestUtils = utils;
  }
}
