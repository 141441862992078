import { Player } from "@lottiefiles/react-lottie-player";
import { CSSProperties } from "react";
import { getPathPrefix } from '../utils/getPathPrefix';

export default function AppLoader({
  customStyle,
  lottieSrc = getPathPrefix("/images/loading-v3.json"),
}: {
  customStyle?: CSSProperties;
  lottieSrc?: string;
}) {
  return (
    <Player
      autoplay
      loop
      src={lottieSrc}
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        width: "300px",
        height: "300px",
        transform: "translate(-50%, -50%)",
        ...customStyle,
      }}
    />
  );
}
