import LoadedState from "frontend/state/loadedState";
import { atom } from "jotai";
import { Account } from "shared/datamodel/schemas/account";
import { Users } from "shared/datamodel/schemas/app-state";
import { User, UsersCache } from "shared/datamodel/schemas/user";
import { TipName } from "../shared/datamodel/schemas/tip";
import { Board } from "../shared/datamodel/schemas/board";

export const accountAtom = atom<Account | null>(null);

export const userAtom = atom<User|null>(null);

export const usersAtom = atom<Users>(new Map());

export const usersStateAtom = atom<LoadedState>(LoadedState.initial);

export const userTipsAtom = atom<Set<TipName>>(new Set<TipName>());

export const boardAtom = atom<Board|null>(null);

export const showSignupFormAtom = atom<boolean>(false);

export const userAccountCountAtom = atom<number>(0);

export const accountSubscribedCountAtom = atom<number>(0);

export const userCacheAtom = atom<UsersCache>({});

export const restrictedUserCacheAtom = atom<{[key: string]:  User[]}>({}); // key is type-id (e.g. team-1)>({});

export const hasMoreAtom = atom(true);