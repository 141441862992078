export enum Action {
  UpdatedUser = "UPDATED_USER",
  FailedLoadingUser = "FAILED_LOADING_USER",
  LoadingBoards = "LOADING_BOARDS",
  FailedLoadingBoards = "FAILED_LOADING_BOARDS",
  UpdatedBoards = "UPDATED_BOARDS",
  DeleteBoard = "DELETE_BOARD",
  FailedDeleteBoard = "FAILED_DELETE_BOARD",
  EditedBoard = "EDITED_BOARD",
  FailedEditBoard = "FAILED_EDIT_BOARD",
  CreatedBoard = "CREATED_BOARD",
  LoadingSpecificBoard = "LOADING_SPECIFIC_BOARD",
  LoadedSpecificBoard = "LOADED_SPECIFIC_BOARD",
  LoadingUsers = "LOADING_USERS",
  UpdatedUsers = "UPDATED_USERS",
  UpdatedUsersSubset = "UPDATED_USERS_SUBSET",
  FailedLoadingUsers = "FAILED_LOADING_USERS",
  PromotionLoaded = "PROMOTION_LOADED",
  PricingPromotionLoaded = "PRICING_PROMOTION_LOADED",
  SetAccountReps = "SET_ACCOUNT_REPS",
  CreatedTeam = "CREATED_TEAM",
  LoadingTeams = "LOADING_TEAMS",
  FailedLoadingTeams = "FAILED_LOADING_TEAMS",
  UpdatedTeams = "UPDATED_TEAMS",
  DeleteTeam = "DELETE_TEAM",
  FailedDeleteTeam = "FAILED_DELETE_TEAM",
  EditedTeam = "EDITED_TEAM",
  FailedEditTeam = "FAILED_EDIT_TEAM",
  SelectTeam = "SELECTED_TEAM",
  FailedSelectTeam = "FAILED_SELECT_TEAM",
  CreatedProject = "CREATED_PROJECT",
  EditedProject = "EDITED_PROJECT",
  FailedEditProject = "FAILED_EDIT_PROJECT",
  DeleteProject = "DELETE_PROJECT",
  FailedDeleteProject = "FAILED_DELETE_PROJECT",
}

export type DispatchEvent = {
  type: Action;
  payload: any | null;
};

export type DispatchFunc = (e: DispatchEvent) => void;
