import { z } from "zod";

export enum PromotionType {
  EMAIL = "email",
  IN_APP = "in-app",
}

export const promotionScheman = z
  .object({
    id: z.string(),
    duration: z.number(),
    maxRedemptions:  z.number().nullish(),
    couponCode: z.string(),
    discount: z.number(),
    validUntill: z.date(),
    type: z.nativeEnum(PromotionType),
    isDismissed: z.boolean().nullish(),
    metadata:z.any().nullable().optional()
  })
  .nullable();

export type PromotionInfo = z.infer<typeof promotionScheman>;
