import mixpanel from "mixpanel-browser";
import posthog from "posthog-js";
import { User } from "shared/datamodel/schemas";

export function setUpAnalyticTools(user: User) {
  if (typeof window === "undefined") return;

  posthog.identify(user.id.toString(), user);
  posthog.group("account", `id::${user?.accountId || user.account?.id || ""}`, {});

  mixpanel.identify(user.id.toString());
  mixpanel.register(user);
  mixpanel.people.set(user);

  if (
    user.account?.id === "5" ||
    process.env.NEXT_ENV !== "production" ||
    (window as any)._DATADOG_SYNTHETICS_BROWSER !== undefined
  ) {
    mixpanel.opt_out_tracking(); //don't send data for monday account users or in dev or for datadog bots
  }

  setFirstTimeUserInitialProperties(user);

  syncFeatureFlags();
}

function getSafeUrlPathname(url: string) {
  try {
    return new URL(url).pathname;
  } catch (e) {
    return url;
  }
}

function setFirstTimeUserInitialProperties(user: User) {
  const storedPersonProps = posthog.get_property("$stored_person_properties");

  if (!storedPersonProps?.$initial_pathname) {
    const initialPersionInfo = posthog.get_property("$initial_person_info")?.u || window.location.hostname;
    const propertiesForFeatureFlags = {
      ...user,
      $initial_pathname: getSafeUrlPathname(initialPersionInfo),
    };

    posthog.setPersonPropertiesForFlags(propertiesForFeatureFlags);
  }
}

function syncFeatureFlags() {
  posthog.onFeatureFlags((_flags, variants) => {
    Object.entries(variants).forEach(([key, value]) => {
      if (key.includes("ab-test")) {
        mixpanel.register({ [key]: value });
        mixpanel.people.set(key, value);
      }
    });
  });
}
