import { z } from "zod";

export const accountSchema = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
  logo: z.string().nullish(),
});

export function accountFromDBModel(obj: any): Account {
  return accountSchema.parse(obj);
}

export type Account = z.infer<typeof accountSchema>;
