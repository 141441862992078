import consts from "shared/consts";

export function readCookieValue(cookieName: string) {
  try {
    const allCookies = document.cookie.split(";");
    if (allCookies) {
      const cookie = allCookies.map((i) => i.trim()).filter((i) => i.includes(cookieName));
      if (cookie && cookie.length > 0) {
        return cookie[0].split("=")[1];
      }
    }
  } catch (error) {
    return null;
  }
}

export function deleteCookie(name: string, path?: string, domain?: string) {
  const allCookies = document.cookie.split(";");
  const cookie = allCookies.map((i) => i.trim()).find((i) => i.includes(name));
  if (cookie) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "/") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT"; //expire the cookie to delete it
  }
  return cookie;
}

export function deleteSessionCookie() {
  const isInIframe = window !== window?.parent;
  const deletedCookie = deleteCookie(consts.COOKIE_NAMES.SESSION);
  const urlParams = new URLSearchParams(window.location.search);
  const testValue = urlParams.get("test");
  console.log("testValue", testValue);
  console.log("isInIframe", isInIframe);
  console.log("Deleted cookie", deletedCookie);
  
  if (!deletedCookie && isInIframe && testValue === "iframe-test") {
    throw new Error("Failed to delete session cookie");
  }
}

export function setCookie(name: string, value: string) {
  const now = new Date();
  now.setMonth(now.getMonth() + 12);
  document.cookie = `${name}=${value};expires=${now};domain=.workcanvas.com;path=/`;
}
