import { atomWithHash } from "jotai/utils";
import Router from "next/router";

export const focusedElementIdAtom = atomForUrlParam("focus");

export const debugWorkerAtom = atomForUrlParam("debugWorker");

export const inviteContactsSourceAtom = atomForUrlParam("inviteSource");

export const onboardingStepAtom = atomForUrlParam("onboarding_step");

export const createIntegrationTypeAtom = atomForUrlParam("create_integration");

export const showModalAtom = atomForUrlParam("show");

function atomForUrlParam(name: string, initialValue?: string) {
  return atomWithHash<string | null | undefined>(name, initialValue, {
    serialize: (value) => value ?? "",
    deserialize: (value) => value,
    replaceState: false,
    subscribe: (callback) => {
      Router.events.on("routeChangeComplete", callback);
      window.addEventListener("hashchange", callback);
      return () => {
        Router.events.off("routeChangeComplete", callback);
        window.removeEventListener("hashchange", callback);
      };
    },
  });
}

