import { z } from "zod";

export const createTemplateLinkSchema = z.object({
  name: z.string().min(1),
  createdFromBoardDocumentId: z.string().min(1),
  data: z.record(z.string(), z.any()),
});

export const updateTemplateLinkSchema = z.object({
  name: z.string().min(1),
  linkId: z.string().min(1),
  data: z.record(z.string(), z.any()),
});

export const templateLinkSchema = z.object({
  id: z.number(),
  name: z.string(),
  linkId: z.string(),
  createdFromBoardDocumentId: z.string(),
  updatedAt: z.date(),
  data: z.record(z.string(), z.any()),
  isOwner: z.boolean().optional(),
});

export function mapTemplateLinkFromDB(object: any, schema: any = templateLinkSchema): TemplateLink {
  return schema.parse({
    id: object.id,
    name: object.name,
    linkId: object.link_id,
    createdFromBoardDocumentId: object.created_from_board_document_id,
    updatedAt: new Date(object.updated_at),
    data: object.data,
    isOwner: object.is_owner,
  });
}

export type CreateTemplateLinkForm = z.infer<typeof createTemplateLinkSchema>;
export type TemplateLink = z.infer<typeof templateLinkSchema>;
export type UpdateTemplateLinkForm = z.infer<typeof updateTemplateLinkSchema>;
